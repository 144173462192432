import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { productInputs, restaurantInputs } from "../app/components/dashboard/formSource";
import DashBoardHomePage from "../app/components/dashboard/pages/home/Home";
import InventoryList from "../app/components/dashboard/pages/list/inventory/restaurant_with_inventory";
import List from "../app/components/dashboard/pages/list/List";
import Single from "../app/components/dashboard/pages/single/Single";
import New from "../app/components/dashboard/pages/new/New";
import HomePage from "../app/components/home/home";
import LoginPage from "../app/components/home/loginsignup/loginpage";
import RegistrationPage from "../app/components/home/loginsignup/signuppage";
import ProductGridPage from "../app/components/products/products_grid";
import ProductConfigComponent from "../app/components/dashboard/pages/productconfig/product-config";
import ContactUsPage from "../app/components/contact/contact-us";
import FAQPage from "../app/components/company/faq";
import AboutUsPage from "../app/components/company/about-us";
import TestimonialsPage from "../app/components/company/testimonials";
import InventoryConfigComponent from "../app/components/dashboard/pages/inventoryconfig/inventory-config";
import AgentsDashboard from "../agents/agentdashboard";
import AgentBusinessRegistrationPage from "../agents/modules/AgentBusinessRegister";
import AgentsRestaurantsResetPassword from "../agents/modules/AgentsRestaurantsResetPassword";
import AgentsRestaurantSetup from "../agents/modules/AgentsRestaurantSetup";
import AgentsAddNewRestaurants from "../agents/modules/AddNewRestaurants";
import AgentConfigureProduct from "../agents/modules/AgentConfigureProduct";
import AgentConfigLiteSpecial from "../agents/modules/AgentConfigLiteSpecial";
import AgentsAdvancedInventoryConfig from "../agents/modules/AgentsInventoryConfig";

function AppRoutes() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');

    return (
        <div className={"app"}>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/all-products" element={<ProductGridPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                {isAuthenticated ? <>
                    {/* standard user routes */}
                    <Route path="/config" element={<DashBoardHomePage />} />
                    <Route path="/config/restaurants" element={<List title='Restaurants' />} />
                    <Route path="/config/restaurants/:userId" element={<Single />} />
                    <Route
                        path="/config/restaurants/new"
                        element={<New inputs={restaurantInputs} title="Add New Restaurant" />}
                    />
                    <Route path="/config/products" element={<List title='Products' />} />
                    <Route path="/config/products/:productId" element={<ProductConfigComponent />} />
                    <Route path="/config/inventory" element={<InventoryList title='Inventory' />} />
                    <Route path="/config/inventory/:inventory" element={<InventoryConfigComponent />} />
                    <Route
                        path="/all-products"
                        element={<New inputs={productInputs} title="Add New Product" />}
                    />

                    {/* agent routes */}
                    <Route path="/agents/dashboard" element={<AgentsDashboard />} />
                    <Route path='/agents/config/register' element={<AgentBusinessRegistrationPage />} />
                    <Route path='/agents/config/resetpassword' element={<AgentsRestaurantsResetPassword />} />
                    <Route path='/agents/config/restaurants' element={<AgentsRestaurantSetup />} />
                    <Route path="/agents/config/restaurants/register" element={<AgentsAddNewRestaurants />} />
                    <Route path="/agents/config/products" element={<AgentConfigureProduct />} />
                    <Route path="/agents/config/products/lite-special" element={<AgentConfigLiteSpecial />} />
                    <Route path="/agents/config/products/advanced-inventory" element={<AgentsAdvancedInventoryConfig />} />



                </> : <>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/registration" element={<RegistrationPage />} />
                </>}

                {/* Catch-all route */}
                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            </Routes>
        </div>
    );
}

export default AppRoutes;
