import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { restaurantColumns, productColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../../../elements/modal/modal";
import ApiLoader from "../../../../elements/isloading";
import "./datatable.scss";
import axios from "../../../../../api/axios";
import LinkRestoToProduct from "../../pages/single/newsub";

const Datatable = ({ title }) => {
  const [productData, setProductData] = useState([]);
  const [restaurantData, setRestaurantData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [unlink, setUnlinking] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const data = title === 'Products' ? productData : restaurantData;
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      try {
        const response = await axios.get(title === 'Products' ? `businessEntities/getAllSubscribedRestoNProdsViaGroupId/${userData.Group_ID}` : `businessEntities/getAllBusinessEntities/${userData.Group_ID}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        title === 'Products' ? sessionStorage.setItem('prodCount', response.data.allProdsNRestos.length) : sessionStorage.setItem('resCount', response.data.length);
        title === 'Products' ? setProductData(response.data.allProdsNRestos) : setRestaurantData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [title, productData, restaurantData]);

  const handleDelete = (id) => {
    const setData = title === 'Products' ? setProductData : setRestaurantData;
    setData(data.filter((item) => item.id !== id));
    setSelectedRow(null);
  };

  const updateActiveStatus = async ({ flag, id }) => {
    setApiLoading(true)
    try {
      const response = await axios.get(title === 'Products' ? `businessEntities/updateRestoProductStat/${flag}/${id}` : `businessEntities/updateRestoStat/${flag}/${id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error updating status data:', error);
    }
    setApiLoading(false)
  };

  const unlinkRestoProd = async ({ id }) => {
    setApiLoading(true)
    try {
      const response = await axios.get(`businessEntities/unlinkRestoAndProduct/${id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error unlinking resto and prod:', error);
    }
    setApiLoading(false)
  };

  const actionColumn = title === 'Products' ? [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {params.row.is_active === 1 ? <Link
              onClick={() => {
                const configurePath = `${params.row.businessName}/${params.row.productName}`;
                sessionStorage.setItem('configurePath', configurePath);
                sessionStorage.setItem('configBizEntityId', params.row.businessEntityId);
              }}
              to={params.row.productId === 3 ? "/config/inventory/configure" : "/config/products/configure"}
              style={{ textDecoration: "none" }} 
            >
              <div className="viewButton">Configure</div>
            </Link> : <Link
              onClick={() => {
                setSelectedRow(params.row);
                setUnlinking(true);
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Unlink</div>
            </Link>}
            <Link
              onClick={() => {
                if (title === 'Products') {
                  params.row.is_active === 0 ? updateActiveStatus({ flag: 1, id: params.row.bepm_id }) : updateActiveStatus({ flag: 0, id: params.row.bepm_id });
                } else {


                }
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">{params.row.is_active === 0 ? 'Activate' : 'Deactivate'}</div>
            </Link>
            <Link
              onClick={() => {
                window.open("https://drive.google.com/file/d/1_zxh5mQmE3G12PwntmdPMIOUWhstmsNB/view?usp=drive_link", '_blank');
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">{'Download'}</div>
            </Link>


          </div>
        );
      },
    },
  ] : [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to="/config/restaurants/test"
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="editButton"

            >
              Edit
            </div>
            <div
              className={
                params.row.isConfigured === 0 ? "blockButton" : "activateButton"
              }

            >
              {params.row.isConfigured === 0 ? "Inactive" : "Activate"}
            </div>
            <div
              className="deleteButton"
              onClick={() => setSelectedRow(params.row)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: 'flex', alignItems: 'center' }}>
        {title}
        <Link
          onClick={() => title === 'Products' ? setShowPopup(true) : {}}
          to={title === 'Products' ? "" : "/config/restaurants/new"}
          className="link"
          style={{ marginLeft: '10px' }}
        >
          {title === 'Products' ? 'Link Restaurant To Product' : 'Add New Restaurant'}
        </Link>
        {title === 'Products' ?
          <Link to={"/all-products"} className="link" style={{ marginLeft: '10px' }}>
            New Subscription
          </Link> : <></>}
      </div>

      <DataGrid
        className="datagrid"
        rows={data}
        getRowId={(row) => title === 'Products' ? row.bepm_id : row.business_entity_id}
        columns={title === 'Products' ? productColumns.concat(actionColumn) : restaurantColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />
      {selectedRow === true && title !== 'Products' ? (
        <ConfirmDeleteModal
          setOpenModal={setSelectedRow}
          message={`Are you sure you want to delete "${selectedRow.restoname}"?`}
          description={'You won\'t be able to revert this!'}
          onDelete={() => handleDelete(selectedRow.bepm_id)}
        />
      ) : <></>}
      {unlink && (
        <ConfirmDeleteModal
          setOpenModal={setUnlinking}
          message={`Are you sure you want to unlink  "${selectedRow.businessName} and ${selectedRow.productName}"?`}
          description={'You won\'t be able to revert this!'}
          onDelete={() => unlinkRestoProd({ id: selectedRow.bepm_id })}
        />
      )}
      {showPopup && < LinkRestoToProduct showPopup={setShowPopup} />}
      {apiLoading && <ApiLoader />}
    </div>
  );
};

export default Datatable;
