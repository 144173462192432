import BBQImage from "../../elements/bbq-image";


function GeneralFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                    How BuyByeQ Platform can help my Restaurant Business?

                                    </button>
                                    <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        BuybyeQ is a complete platform that simplifies your hospitality business operations,
providing solutions for POS billing, inventory management, and reporting to streamline every
aspect of your restaurant, hotel, bakery, or food truck.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                    How do I get started with BuybyeQ?
                                    </button>
                                    <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Get started by registering your business at www.buybyeq.com. Once registered, you’ll
receive your login credentials, allowing you to add your restaurants and choose the product
and plan that best fit your needs. Our team is here to assist with setup and provide training
as needed.
                                        </div>
                                    </div>
                                </div>
                            
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                    Can I use BuybyeQ products on multiple devices?

                                    </button>
                                    <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Absolutely! Our Products are designed for multiple devices, making it easy for teams to work
seamlessly
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
                                    Does BuybyeQ support multi-location businesses?
                                    </button>
                                    <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Yes, BuybyeQ can manage multiple locations, helping you track and streamline operations
across all your business sites.

                                        </div>
                                    </div>
                                </div>
                                {/*Six*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
                                    Can I generate business reports with BuybyeQ?
                                    </button>
                                    <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Yes, BuybyeQ includes detailed business reporting features to provide insights on sales,
                                        inventory, and other key business metrics.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ7" aria-expanded="false">
                                    How can I contact BuybyeQ for inquiries?
                                    </button>
                                    <div id="FAQ7" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        If you have any questions, we’re here to help! You can reach BuybyeQ through our website’s
“Contact Us” page, by emailing us at mybuybyeq@gmail.com, or by calling the contact
number listed on our website. Our customer service team is ready to assist you with any
inquiries or additional information you may need.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <BBQImage src="images/faq-img-1.jpg" alt="#" />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

function HelpFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1-1" aria-expanded="false">
                                    How can I get technical support for a subscribed product ?
                                    </button>
                                    <div id="FAQ1-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        If you require technical support , please reach out to our dedicated support team through the contact details provided in contact us page. We prioritize swift responses to ensure minimal disruptions.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2-1" aria-expanded="false">
                                    Can I schedule a consultation with BuyByeQ to discuss my ideas to improve the app?
                                    </button>
                                    <div id="FAQ2-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        Yes, we encourage it! You can schedule a consultation through our website, where we'll discuss your project ideas, goals, and provide insights on how BuyByeQ can bring your vision to life.
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <BBQImage src="images/faq-img-2.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionFAQContent() {
    return (
        <>
            <div className="tab-content">
                {/*1*/}
                <div className="tab-pane fade show active" id="day_1" role="tabpanel">
                    <GeneralFAQContent />
                </div>
                {/*2*/}
                {/* <div className="tab-pane fade" id="day_2" role="tabpanel">
                    <HelpFAQContent />
                </div> */}
            </div>
        </>
    )
}

export default SectionFAQContent;