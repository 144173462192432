import React, { useState, useEffect } from 'react';
import axios from "../../src/api/axios";
import { DataGrid } from '@mui/x-data-grid';
import { Building2, AlertCircle } from 'lucide-react';
import AgentSidebar from './components/AgentSidebar';
import Navbar from '../app/components/dashboard/components/navbar/Navbar';


let entitiesUrl = "businessEntities/withoutRestaurants";


const AgentsDashboard = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessesWithoutRestaurants = async () => {
      try {
        const response = await axios.get(entitiesUrl);
        setBusinesses(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch businesses');
        setLoading(false);
        console.error('Error fetching businesses:', err);
      }
    };

    fetchBusinessesWithoutRestaurants();
  }, []);


  const columns = [
    { field: 'business_entity_id', headerName: 'Business ID', width: 150 },
    { field: 'business_name', headerName: 'Business Name', width: 200 },
    { field: 'name', headerName: 'Owner Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'contact_no', headerName: 'Contact', width: 150 },
    { field: 'business_type', headerName: 'Type', width: 100 }
  ];

  return (
    <div className="new">
      < AgentSidebar />
      <div className="newContainer flex flex-col h-screen overflow-hidden">
      <Navbar/>
        <div className="flex-grow overflow-y-auto p-6">
          <div className="mb-6">
            <h1 className="text-2xl font-bold mb-2">Businesses Without Restaurants</h1>
            {/* <p className="text-gray-600">Showing all registered businesses that haven't set up any restaurants yet.</p> */}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="flex items-center space-x-2">
                <Building2 className="h-5 w-5 text-blue-500" />
                <h2 className="text-lg font-semibold">Total Pending Setup</h2>
              </div>
              <p className="text-3xl font-bold mt-2">{businesses.length}</p>
            </div>
          </div>

          {loading ? (
            <div className="text-center py-8">Loading businesses...</div>
          ) : error ? (
            <div className="text-center py-8 text-red-500">
              <AlertCircle className="h-8 w-8 mx-auto mb-2" />
              {error}
            </div>
          ) : (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={businesses}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.business_entity_id}
                autoHeight
                disableSelectionOnClick
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentsDashboard;