import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SectionCard from '../cards/section_card';
import Modal from '@mui/material/Modal';
import '../lists/section_lst.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function SectionList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [sections, setSections] = useState([]);
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchData();
  }, [sections, business_entity_id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`businessEntities/getAllSections/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setSections(response.data.allSections);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const [newSectionDescription, setNewSectionDescription] = useState('');
  const [currentSection, setCurrentSection] = useState(null);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const handleOpenModal = (section = null) => {
    setCurrentSection(section);
    if (section) {
      setNewSectionName(section.section_name);
      setNewSectionDescription(section.description);
    } else {
      setNewSectionName('');
      setNewSectionDescription('');
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrMsg("");
  };

  const handleSaveSection = async () => {
    if (!newSectionName.trim()) {
      setErrMsg("Section name is required.");
      setTimeout(() => setErrMsg(""), 3000);
      return;
    }
  
    try {
      const payload = {
        sectionName: newSectionName,
        sectionDescription: newSectionDescription,
        businessEntityId: business_entity_id,
      };
  
      if (currentSection) {
        payload.sectionId = currentSection.section_id;
      }

      const response = await axios.post(
        'businessEntities/updateSection',
        payload,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        fetchData();
        setOpenModal(false);
        setSnackbarMessage(currentSection ? 'Section updated successfully' : 'Section added successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrMsg("Error: operation failed");
      setTimeout(() => setErrMsg(""), 3000);
    }
  };
  

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      setSnackbarMessage("Please upload a valid CSV file.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxFileSize) {
      setSnackbarMessage("File size exceeds the maximum limit of 5MB.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurant_id', business_entity_id);

    setIsUploading(true);

    try {
      const response = await axios.post(
        '/businessEntities/upload-section-csv',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: false,
        }
      );

      const successMessage = response.data.message || "Section CSV uploaded successfully!";
      fetchData();

      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      let errorMessage = 'Something went wrong with the section CSV upload!';

      if (error.response) {
        errorMessage = error.response.data.error || `Error: ${error.response.statusText}`;
      } else if (error.request) {
        errorMessage = 'No response from the server. Please try again later.';
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      console.error('Error uploading CSV:', error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDelete = async (section_id) => {
    try {
      const response = await axios.delete(`businessEntities/deleteSection/${section_id}`);
      
      if (response.status === 200) {
        fetchData();
        setSnackbarMessage("Section deleted successfully");
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting section item:", error);
      setSnackbarMessage("Failed to delete section");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const filteredSections = sections.filter(section =>
    section.section_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a href="/assets/templates/section.csv" download="section.csv" style={{ textDecoration: 'none' }}>
            Download CSV Template ?
          </a>
        </div>
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search sections"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => handleOpenModal(null)}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Section
          </Button>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
            style={{ backgroundColor: '#2196F3', color: 'white' }}
            startIcon={<CloudUploadIcon />}
          >
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </Button>
        </div>
        {searchTerm !== '' && filteredSections.length === 0 && sections.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your section search!</span>
          </div>
        )}
        {sections.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No sections have been added</span>
          </div>
        )}
      </div>
      <Grid container spacing={3}>
        {filteredSections.map(section => (
          <Grid item key={section.section_id} xs={12} sm={6} md={4} lg={3}>
            <SectionCard
              section={section.section_name}
              description={section.description}
              onEdit={() => handleOpenModal(section)}
              onDelete={() => handleDelete(section.section_id)}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="section-modal"
        aria-describedby="section-description"
      >
        <div className="modal-container">
          <h6 id="section-modal">{currentSection ? "Update Section" : "Add New Section"}</h6>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg-login" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <label htmlFor="section-name">Section Name:</label>
            <input
              id="section-name"
              type="text"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
            />
            <label htmlFor="section-description">Description:</label>
            <textarea
              id="section-description"
              value={newSectionDescription}
              onChange={(e) => setNewSectionDescription(e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
              <Button
                variant="contained"
                onClick={handleSaveSection}
                style={{ paddingRight: '16px' }}
              >
                {currentSection ? "Update" : "Save"}
              </Button>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default SectionList;
