import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

function Footer() {
    
    const location = useLocation();
    const isContactPage = location.pathname === "/contact-us";

  
    return (
        <footer className="site-footer footer-dark" >
            {!isContactPage && (
            <div className="sx-f-nl-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h2 className="sx-f-title">
                                Want to join BuyByeQ.
                            </h2>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sx-nl-form">
                                <form>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                        <div className="sx-f-call-section">
                                            <a href="tel:+919861094684">+91 9861094684</a>
                                            <a href="tel:+919937989195">+91 9937989195</a>
                                        </div>
                                    </div>
                                    <div className="sx-nl-form-inner">
                                        <div className="widget-info-icon" style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"23px", width:"60px", paddingTop:"15px"}}>
                                            <i className="flaticon-email" />
                                        </div>
                                        <input type="text" className="sx-nl-form-input" placeholder="mybuybyeq@gmail.com" />
                                        <button className="sx-nl-form-btn"><i className="flaticon-back-left" /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                )}
            
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 ">
                            <div className="widget widget_about">
                                <h4 className="sx-f-title">BUYBYEQ PLATFORM</h4>
                                <p className="mb-4 fs-6">BuyByeQ is your all-in-one platform to power up hospitality! From restaurants and hotels to bakeries
and food trucks, our platform simplifies every operation, so you can focus on delighting your
customers.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 ">
                            <div className=" widget_services">
                                <h4 className="sx-f-title">EXPLORE LINKS</h4>
                                <ul className="d-flex flex-row gap-5 justify-content-center ">
                                    <li><NavLink to="/all-products" className="fs-6">Our Products</NavLink></li>
                                    <li><NavLink to="/faq" className="fs-6">FAQ</NavLink></li>
                                    <li><NavLink to="/contact-us" className="fs-6">Contact</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sx-f-bottom-section">
                <div className="sx-f-social">
                    <ul>
                        <li><a href="https://www.facebook.com/"><i className="flaticon-facebook" /></a></li>
                        {/* <li><a href="https://twitter.com/"><i className="flaticon-twitter" /></a></li> */}
                        <li><a href="https://www.linkedin.com/"><i className="flaticon-linkedin" /></a></li>
                        <li><a href="https://www.instagram.com/"><i className="flaticon-instagram" /></a></li>
                    </ul>
                </div>
                <div className="sx-f-copy">
    © 2024 <NavLink to="https://www.sigmancomp.com/" target="_blank" rel="noopener noreferrer">
        Sigma Analytics and Computing.
    </NavLink> All Rights Reserved.
</div>
            </div>
        </footer>
    )
}

export default Footer;