import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { X } from "lucide-react";
import AgentSidebar from "../components/AgentSidebar";
import Navbar from "../../app/components/dashboard/components/navbar/Navbar";

const Modal = ({ isOpen, onClose, onSubmit, entityName }) => {
  const [newPassword, setNewPassword] = useState("");

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(newPassword);
    setNewPassword("");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Reset Password</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="mb-4">Enter new password for {entityName}</p>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full p-2 border rounded mb-4"
          placeholder="New password"
        />
        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const AgentsRestaurantsResetPassword = () => {
  const [entities, setEntities] = useState([]);
  const [businessSearchQuery, setBusinessSearchQuery] = useState("");
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.get('businessEntities/getBusinessEntitiesforpasswordreset');
        setEntities(response.data);
      } catch (err) {
        console.error("Error fetching business entities:", err);
      }
    };

    fetchEntities();
  }, []);

  const handlePasswordReset = async (newPassword) => {
    if (!selectedEntity || !selectedEntity.user_id) {
      console.error("No user selected for password reset.");
      return;
    }

    try {
      await axios.put(`businessEntities/passwordreset/${selectedEntity.user_id}`, { password: newPassword });
      const updatedEntities = entities.map(e =>
        e.user_id === selectedEntity.user_id ? { ...e, password: newPassword } : e
      );
      setEntities(updatedEntities);
      alert(`Password updated for ${selectedEntity.business_name}`);
      setIsModalOpen(false);
    } catch (err) {
      console.error("Error resetting password:", err);
    }
  };

  // Filter entities based on both search queries
  const filteredEntities = entities.filter(entity => {
    let businessMatch = true;
    let userMatch = true;

    if (businessSearchQuery !== "") {
      businessMatch = 
        entity.business_entity_id?.toString() === businessSearchQuery ||
        entity.business_name.toLowerCase() === businessSearchQuery.toLowerCase();
    }

    if (userSearchQuery !== "") {
      userMatch = 
        entity.user_id?.toString() === userSearchQuery ||
        entity.username === userSearchQuery;
    }

    return businessMatch && userMatch;
  });

  const handleBusinessSearch = (e) => {
    setBusinessSearchQuery(e.target.value);
    setUserSearchQuery(""); // Clear user search when business search changes
  };

  const handleUserSearch = (e) => {
    setUserSearchQuery(e.target.value);
    setBusinessSearchQuery(""); // Clear business search when user search changes
  };

  //   <tr key={entity.business_entity_id} className="hover:bg-gray-100">
  //     {renderTableCell(entity.business_entity_id, businessSearchQuery)}
  //     {renderTableCell(entity.user_id, userSearchQuery)}
  //     {renderTableCell(entity.username, userSearchQuery)}
  //     <td className="px-4 py-2 border">{entity.email}</td>
  //     <td className="px-4 py-2 border">{entity.role_description}</td>
  //     <td className="px-4 py-2 border">{entity.restaurant_name}</td>
  //     {renderTableCell(entity.business_name, businessSearchQuery)}
  //     <td className="px-4 py-2 border">{entity.password}</td>
  //     <td className="px-4 py-2 border">
  //       <button
  //         onClick={() => {
  //           setSelectedEntity(entity);
  //           setIsModalOpen(true);
  //         }}
  //         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
  //       >
  //         Reset Password
  //       </button>
  //     </td>
  //   </tr>
  // );

  return (
    <div className="new">
      <AgentSidebar />
      <div className="newContainer flex flex-col h-screen overflow-hidden">
        <Navbar />
        <div className="container flex-grow overflow-y-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Business Entities</h1>

          <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <div className="flex-1">
            <input
                type="text"
                placeholder="Enter exact Business ID or Name"
                value={businessSearchQuery}
                onChange={handleBusinessSearch}
                className="w-full border rounded-lg px-4 py-2"
              />
            </div>
            <div className="flex-1">
            <input
                type="text"
                placeholder="Enter exact User ID or Username"
                value={userSearchQuery}
                onChange={handleUserSearch}
                className="w-full border rounded-lg px-4 py-2"
              />
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2 border">Business Entity ID</th>
                  <th className="px-4 py-2 border">User ID</th>
                  <th className="px-4 py-2 border">Username</th>
                  <th className="px-4 py-2 border">Email</th>
                  <th className="px-4 py-2 border">Role</th>
                  <th className="px-4 py-2 border">Restaurant</th>
                  <th className="px-4 py-2 border">Business Name</th>
                  <th className="px-4 py-2 border">Password</th>
                  <th className="px-4 py-2 border">Action</th>
                </tr>
              </thead>
              <tbody>
              {filteredEntities.map(entity => (
                  <tr key={entity.business_entity_id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border">{entity.business_entity_id}</td>
                    <td className="px-4 py-2 border">{entity.user_id}</td>
                    <td className="px-4 py-2 border">{entity.username}</td>
                    <td className="px-4 py-2 border">{entity.email}</td>
                    <td className="px-4 py-2 border">{entity.role_description}</td>
                    <td className="px-4 py-2 border">{entity.restaurant_name}</td>
                    <td className="px-4 py-2 border">{entity.business_name}</td>
                    <td className="px-4 py-2 border">{entity.password}</td>
                    <td className="px-4 py-2 border">
                      <button
                        onClick={() => {
                          setSelectedEntity(entity);
                          setIsModalOpen(true);
                        }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Reset Password
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredEntities.length === 0 && (
              <p className="text-center py-4">No matching entities found.</p>
            )}
          </div>

          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handlePasswordReset}
            entityName={selectedEntity?.business_name}
          />
        </div>
      </div>
    </div>
  );
};

export default AgentsRestaurantsResetPassword;



