import SectionSlider from "../../sections/common/home-sections/slider";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import TestimonialsPage from "../company/testimonials";


function HomePage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            <SectionSlider />
            <TestimonialsPage/>
        </>
    )
}

export default HomePage;