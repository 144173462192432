import Banner from "../../sections/common/banner";
import { bannerData } from "../../../globals/banner";
import SectionFAQContent from "./faq_contents";
import SectionFAQTabs from "./faq_tab";

function FAQCombined() {
    return (
        <>
            <div className="section-full  p-t100 p-b0 sx-bg-white sx-faq--outer">
                <div className="container">
                    <div className="aon-faq-row-section">
                        {/* Detail SECTION START */}
                        <div className="sx-faq-style-1-wrap">
                            {/* <SectionFAQTabs /> */}
                            <h4 className="faqheader">General FAQs</h4>
                            <SectionFAQContent />
                        </div>
                        {/* Detail SECTION END */}
                    </div>
                </div>
            </div>
        </>
    )
}

function FAQPage() {
    return (
        <>
            <Banner _data={bannerData.faq} />
            <FAQCombined />
        </>
    )
}

export default FAQPage;