import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from 'react-router-dom';
import AgentSidebar from "../components/AgentSidebar";
import Navbar from "../../app/components/dashboard/components/navbar/Navbar";

const AddProductModal = ({ isOpen, onClose, businessName, groupId, onProductAdded }) => {
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get(`/businessEntities/getAllBusinessEntities/${groupId}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setRestaurants(response.data);
        console.log(response.data)
      } catch (err) {
        console.error("Error fetching restaurants:", err);
        setError("Failed to load restaurants. Please try again.");
      }
    };

    if (isOpen) {
      fetchRestaurants();
    }
  }, [isOpen, groupId]);

  const handleSave = async () => {
    if (!selectedRestaurant || !selectedProduct) {
      setError("Please select both a restaurant and a product.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`/businessEntities/addProductForBusiness`, {
        businessEntityId: selectedRestaurant,
        productId: selectedProduct,
        groupId: groupId
      }, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      console.log('Product added successfully:', response.data);
      onProductAdded();
      onClose();
    } catch (err) {
      console.error("Error adding product:", err);
      let errorMessage = "Failed to add product. Please try again.";
      if (err.response) {
        console.error("Error response data:", err.response.data);
        console.error("Error response status:", err.response.status);
        console.error("Error response headers:", err.response.headers);
        errorMessage = `Server error (${err.response.status}): ${err.response.data.message || err.response.data}`;
      } else if (err.request) {
        console.error("Error request:", err.request);
        errorMessage = "No response received from server. Please check your connection.";
      } else {
        console.error("Error message:", err.message);
        errorMessage = `Error: ${err.message}`;
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Add Product for {businessName}</h2>
        <select
          value={selectedRestaurant}
          onChange={(e) => setSelectedRestaurant(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Select Restaurant</option>
          {restaurants.map((restaurant) => (
            <option key={restaurant.business_entity_id} value={restaurant.business_entity_id}>
              {restaurant.business_name}
            </option>
          ))}
        </select>
        <select
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Select Product</option>
          <option value="1">Special</option>
          <option value="2">Lite</option>
          <option value="3">Advanced Inventory</option>
        </select>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="flex justify-end">
          <button 
            onClick={handleSave} 
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add'}
          </button>
          <button onClick={onClose} className="bg-gray-300 px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};


const AgentConfigureProduct = () => {
  const [businessEntities, setBusinessEntities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const entitiesResponse = await axios.get(`/businessEntities/getAllRegisteredBusinessEntities`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      const entitiesWithRestaurants = await Promise.all(
        entitiesResponse.data.map(async (entity) => {
          const restaurantsResponse = await axios.get(`/businessEntities/getAllSubscribedRestoNProdsViaGroupId/${entity.group_id}`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          });
          console.log(restaurantsResponse.data);
          return { ...entity, restaurants: restaurantsResponse.data.allProdsNRestos };
        })
      );

      setBusinessEntities(entitiesWithRestaurants);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to load data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { field: 'bepm_id', headerName: 'ID', width: 70 },
    { field: 'businessEntityId', headerName: 'Business Entity ID', width: 150 },
    { field: 'businessName', headerName: 'Business Name', width: 200 },
    { field: 'productName', headerName: 'Product Name', width: 150 },
    {
      field: 'is_active',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <div className={params.value === 1 ? "text-green-600" : "text-red-600"}>
          {params.value === 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 320,
      renderCell: (params) => (
        
        <div className="space-x-2">
          
          <button
            className="bg-blue-500 text-white px-2 py-1 rounded "
            onClick={() => handleConfiguration(params.row)}
          >
            Configure
          </button>
          
          <button
            className={`px-2 py-1 rounded ${params.row.is_active === 1 ? 'bg-red-500' : 'bg-green-500'} text-white`}
            onClick={() => handleToggleStatus(params.row)}
          >
            {params.row.is_active === 1 ? 'Deactivate' : 'Activate'}
          </button>

        </div>
      ),
    },
    
  ];


  const handleAddProduct = (entity) => {
    setSelectedBusiness(entity);
    setModalOpen(true);
  };

  const handleProductAdded = () => {
    fetchData();
  };

  if (loading) {
    return <div className="new">
    <AgentSidebar />
    <div className="newContainer flex flex-col  h-screen overflow-hidden">
      <Navbar />
  <div className="container flex-grow overflow-y-auto px-4 py-8">
    <h1 className="text-3xl font-bold mb-6">Products Configuration</h1>

    <div className="text-center py-10">Loading...</div>
  </div>
  </div>
  </div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }




  const handleConfiguration = (row) => {
    const productType = row.productName.toLowerCase(); 
    if (productType === 'special billing' || productType === 'lite billing') {
      const configurePath = `${row.businessName}/${row.productName}`;
      sessionStorage.setItem('configurePath', configurePath);
      sessionStorage.setItem('configBizEntityId', row.businessEntityId);
      navigate(`/agents/config/products/lite-special`);
    } else if (productType === 'advanced inventory') {
      const advancedConfigPath = `${row.businessName}/advanced-inventory`;
      sessionStorage.setItem('configurePath', advancedConfigPath);
      sessionStorage.setItem('configBizEntityId', row.businessEntityId);
      navigate(`/agents/config/products/advanced-inventory`);
    }
  };
  
  
  const handleToggleStatus = async (row) => {
    try {
      await axios.get(
        `/businessEntities/updateRestoProductStat/${row.is_active === 1 ? 0 : 1}/${row.bepm_id}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      await fetchData(); 
    } catch (err) {
      console.error("Error toggling status:", err);
      if (err.response) {
        console.error(`Server error (${err.response.status}): ${err.response.data.message || err.response.data}`);
      } else if (err.request) {
        console.error("No response received from the server. Please check your connection.");
      } else {
        console.error("Error setting up the request:", err.message);
      }
    }
  };
  
  
  const handleDownload = (row) => {
    console.log(`Downloading product info for ${row.bepm_id}`);
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEntities = businessEntities.filter(entity => 
    entity.business_entity_id.toString().includes(searchQuery) || // Search by ID
    entity.business_name.toLowerCase().includes(searchQuery.toLowerCase()) // Search by Business Name
  );

  return (
    <div className="new">
      <AgentSidebar />
      <div className="newContainer flex flex-col  h-screen overflow-hidden">
        <Navbar />
    <div className="container flex-grow overflow-y-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Product</h1>

      <div className="mb-4">
            <input
              type="text"
              placeholder="Search by ID or Business Name..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded-lg px-4 py-2 w-full"
            />
          </div>

          
      {filteredEntities.map((entity) => (
        <div key={entity.group_id} className="mb-8 border rounded-lg overflow-hidden">
          <div className="bg-gray-100 p-4 flex justify-between items-center">
            <div>
              <h2 className="text-xl font-semibold">{entity.business_name}</h2>
              <p><strong>ID:</strong> {entity.group_id}</p>
              <p><strong>Owner:</strong> {entity.name}</p>
              <p><strong>Contact:</strong> {entity.contact_no}</p>
              <p><strong>Email:</strong> {entity.email}</p>
            </div>
            <button
              onClick={() => handleAddProduct(entity)}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Add Product
            </button>
          </div>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={entity.restaurants || []}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowId={(row) => row.bepm_id}
            />
          </div>
        </div>
      ))}
      {businessEntities.length === 0 && (
        <p className="text-center py-4">No matching business entities found.</p>
      )}
      {selectedBusiness && (
        <AddProductModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          businessName={selectedBusiness.business_name}
          // restaurants={selectedBusiness.restaurants}
          groupId={selectedBusiness.group_id}
          onProductAdded={handleProductAdded}
        />
      )}
    </div>
    </div>
    </div>
  );
};

export default AgentConfigureProduct;