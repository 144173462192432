import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { bannerData } from '../../../globals/banner';
import Banner from '../../sections/common/banner';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoMdCloseCircle } from "react-icons/io";
import axios from '../../../api/axios';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <>
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><strong>Billing</strong></h5>
                            <IoMdCloseCircle className="close" style={{ cursor: "pointer" }} onClick={onClose} />
                        </div>
                        <div className="modal-body">{children}</div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};

const ProductCard = ({ product, onSelectProduct, handleUseItClick, isAuthenticated }) => {
    const handleWatchDemo = (demoUrl) => {
        window.open(demoUrl, '_blank');
    };
    const availableOnPlatforms = JSON.parse(product.availableOn || '[]');

    return (
        <div className="card shadow-md mb-4">
            <div className="card-body text-center">
                <img src={product.product_icon} alt={product.product_name} className="mb-3" style={{ width: '64px', height: '64px',display:"inline",justifyContent:"center" }} />
                <h5 className="card-title"><strong>{product.product_name}</strong></h5>
                <p className="card-text" style={{ fontSize: "17px" }}>{product.product_description}</p>
                {availableOnPlatforms.length > 0 && (
                    <div className="mb-3">
                        <span>Available on: </span>
                        {availableOnPlatforms.map((platform, index) => (
                            <div key={index} className="d-inline-flex align-items-center mx-2">
                                <img
                                    src={platform === 'mobile' ? `/assets/images/phoneicon.png` : `/assets/images/desktop.png`}
                                    alt={platform}
                                    className="me-1"
                                    style={{ width: '20px', height: '20px' }}
                                />
                                <span>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span>
                            </div>
                        ))}
                    </div>
                )}

                <button className="btn  w-100 mb-2 " style={{backgroundColor:"#FFA500" , color:"white" ,fontWeight:"500"}}  onClick={() => handleWatchDemo(product.video_url)}>Watch Demo Video</button>
                <button className="btn text-white w-100 mb-3" style={{ backgroundColor: "rgba(108, 117, 125, 0.8)" }}>
                    Try 15 days FREE trial
                </button>
                <div className="d-flex justify-content-between">
                    {product.plans.map((plan, index) => (
                        <button key={index} className="btn text-secondary fw-bold flex-fill mx-1" style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}>
                            ₹ {plan.price}
                            <br />
                            <small>{plan.name}</small>
                        </button>
                    ))}
                </div>
                <NavLink
                    className={`btn btn-primary w-100 mb-3 mt-3 ${isAuthenticated ? '' : 'on-click'}`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (isAuthenticated) {
                            onSelectProduct(product);
                        } else {
                            handleUseItClick();
                        }
                    }}
                >
                    Continue
                </NavLink>

            </div>
        </div>
    );
};

const ProductGrid = () => {
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [restaurant, setRestaurant] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState('Trial');
    const [customAmount, setCustomAmount] = useState('');
    const [amount, setAmount] = useState(0);
    const [selectedRestaurantGroupId, setSelectedRestaurantGroupId] = useState(null);
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.post(`businessEntities/getAllProductsAndPlans`, {
                    userid: userData?.userId || null
                }, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                });
                setProducts(response.data.products || []);
            } catch (error) {
                console.log("Error fetching products. " + error);
            }
        };

        fetchProducts();
    }, [userData]);

    useEffect(() => {
        const fetchBusinessEntities = async () => {
            try {
                const response = await axios.get(`businessEntities/getAllBusinessEntities/${userData?.Group_ID || null}`);
                setRestaurant(response.data || []);
                console.log(response.data);
            } catch (error) {
                console.log("Error fetching business entities. " + error);
            }
        };

        if (userData) {
            fetchBusinessEntities();
        }
    }, [userData]);

    const handleUseItClick = () => {
        setShowAuthDialog(true);
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleRegister = () => {
        navigate('/registration');
    };

    const handleRestaurantChange = (e) => {
        const selectedId = e.target.value;
        setSelectedRestaurant(selectedId);

        const selected = restaurant.find(r => r.business_entity_id.toString() === selectedId);
        if (selected) {
            setSelectedRestaurantGroupId(selected.group_id);
        }
    };

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
        setSubscriptionType('Trial');
        setAmount(0);
        setIsModalOpen(true);
    };

    const handleSubscriptionTypeChange = (e) => {
        const selectedType = e.target.value;
        setSubscriptionType(selectedType);
        setAmount(selectedType === 'Trial' ? 0 : selectedProduct?.plans.find(plan => plan.name === selectedType)?.price || 0);
    };

    const handleCustomAmountChange = (e) => {
        setCustomAmount(e.target.value);
    };

    const handleSubmitSubscription = async () => {
        const finalAmount = subscriptionType === 'Custom' ? parseFloat(customAmount) : amount;

        if (finalAmount <= 0 && subscriptionType != 'Trial') {
            alert("Please enter a valid amount.");
            return;
        }

        if (selectedRestaurant) {
            try {
                const response = await axios.post(`businessEntities/addProductForBusiness`, {
                    businessEntityId: selectedRestaurant,
                    productId: selectedProduct.id,
                    groupId: selectedRestaurantGroupId
                });
                console.log('Product added to restaurant:', response.data);
                alert("Product added successfully!");
            } catch (error) {
                alert('Error adding product to restaurant. Please try again.');
            }
        }

        setSelectedRestaurant('');
        setIsModalOpen(false);
    };

    const handleModalClose = () => {
        setSelectedRestaurant('');
        setIsModalOpen(false);
    };

    return (
        <>
            <Banner _data={bannerData.products} />
            <div className="container py-4">
                <div className="row">
                    {products.map((product, index) => (
                        <div key={index} className="col-md-4">
                            <ProductCard product={product} onSelectProduct={handleSelectProduct} handleUseItClick={handleUseItClick} isAuthenticated={isAuthenticated} />
                        </div>
                    ))}
                </div>

                {showAuthDialog && (
                    <Modal isOpen={showAuthDialog} onClose={() => setShowAuthDialog(false)}>
                        <div className="modal-header d-flex justify-content-between">
                            <h5 className="modal-title"><strong>Are you already a customer?</strong></h5>
                            <div type="button" className="close" onClick={() => setShowAuthDialog(false)}>
                               
                            </div>
                        </div>
                        <div className="modal-body">
                            <button className="btn btn-primary w-100 mb-2" onClick={handleLogin}>Login</button>
                            <button className="btn btn-secondary w-100" onClick={handleRegister}>Register</button>
                        </div>
                    </Modal>
                )}
            </div>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <div className="form-group">
                    <label>Select Restaurant</label>
                    <select value={selectedRestaurant} onChange={handleRestaurantChange} className="form-select mb-3">
                        <option value="">Select Restaurant</option>
                        {restaurant.map((res) => (
                            <option key={res.business_entity_id} value={res.business_entity_id}>
                                {res.business_name}
                            </option>
                        ))}
                    </select>

                    <label>Select Subscription Type</label>
                    <select value={subscriptionType} onChange={handleSubscriptionTypeChange} className="form-select mb-3">
                        <option value="Trial">Trial</option>
                        {selectedProduct && selectedProduct.plans.map((plan, index) => (
                            <option key={index} value={plan.name}>{plan.name}</option>
                        ))}
                        <option value="Custom">Custom Amount</option>
                    </select>

                    {subscriptionType === 'Custom' && (
                        <div>
                            <label>Enter Amount</label>
                            <input type="number" value={customAmount} onChange={handleCustomAmountChange} className="form-control mb-3" />
                        </div>
                    )}

                    <button className="btn btn-success w-100" onClick={handleSubmitSubscription}>Submit</button>
                </div>
            </Modal>
        </>
    );
};

export default ProductGrid;
