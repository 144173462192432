import React, { useRef, useState, useEffect } from "react";
import axios from "../../api/axios";
import AgentSidebar from "../components/AgentSidebar";
import "../../../src/app/components/home/loginsignup/signuppage.css";
import Navbar from "../../app/components/dashboard/components/navbar/Navbar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const registerUrl = "registration/start";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AgentBusinessRegistrationPage() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fullNameRef = useRef(null);
  const businessNameRef = useRef(null);
  const contactRef = useRef(null);
  const addressRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    setErrMsg("");
    return () => {
      setErrMsg("");
    };
  }, []);

  const handleRegistration = async (event) => {
    event.preventDefault();
  
    const fullName = fullNameRef.current.value;
    const businessName = businessNameRef.current.value;
    const contact = contactRef.current.value;
    const address = addressRef.current.value;
    const email = emailRef.current.value;
  
    if (fullName !== "" && businessName !== "" && contact !== "" && email !== "" && address !== "") {
      try {
        setSubmitting(true);
  
        const response = await axios.post(
          registerUrl,
          {
            businessName,
            Name: fullName,
            email,
            contactNo: contact,
            address,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          }
        );
  
        setOpenSnackbar(true); 

        fullNameRef.current.value = "";
        businessNameRef.current.value = "";
        contactRef.current.value = "";
        addressRef.current.value = "";
        emailRef.current.value = "";
  
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrMsg(error.response.data.error);
          setTimeout(() => setErrMsg(""), 3000);
        } else {
          setErrMsg("Error: Registration failed.");
          setTimeout(() => setErrMsg(""), 3000);
        }
        console.error("Error during registration:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      setErrMsg("Please fill all required fields");
      setTimeout(() => setErrMsg(""), 3000);
    }
  };
  



  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      <div className="list">
        <AgentSidebar />
        <div className="listContainer">
          <Navbar />
          <div className="signup-container ">
            <div className="signup-form">
              <div className="header">
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg-login" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    ref={fullNameRef}
                    placeholder="Enter full name for business owner"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    ref={businessNameRef}
                    placeholder="Enter business name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    ref={contactRef}
                    placeholder="Enter owner contact"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    ref={addressRef}
                    placeholder="Enter address"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    ref={emailRef}
                    placeholder="Enter owner/business email"
                    autoComplete="off"
                    required
                  />
                </div>

                <button
                  className="button-enabled max-width"
                  type="submit"
                  onClick={handleRegistration}
                  disabled={submitting}
                >
                  {submitting ? "Registering..." : "Register Business"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>


      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          Registration of business was successful!
        </Alert>
      </Snackbar>

    </div>
  );
}

export default AgentBusinessRegistrationPage;
