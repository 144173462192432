import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CategoryCard from './cards/category _card';
import Modal from '@mui/material/Modal';
import '../category/category-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../api/axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function CategoryList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const errRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchData();
  }, [categories, business_entity_id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`businessEntities/getAllMenuCategories/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setCategories(response.data.allCategories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  const handleAddCategory = () => {
    setIsEditMode(false);
    setOpenModal(true);
    setNewCategoryName('');
    setNewCategoryDescription('');
  };

  const handleEditCategory = (category) => {
    setIsEditMode(true);
    setCurrentCategory(category);
    setNewCategoryName(category.category_name);
    setNewCategoryDescription(category.description);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddOrEditCategorySubmit = async () => {
    if (!newCategoryName.trim()) {
      setSnackbarMessage("Category name is required.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 3000);
      return;
    }

    try {
      const payload = {
        menuName: newCategoryName,
        menuDescription: newCategoryDescription === '' ? 'Satisfying main dishes for a hearty meal.' : newCategoryDescription,
        businessEntityId: sessionStorage.getItem("configBizEntityId"),
      };

      if (isEditMode) {
        payload.categoryId = currentCategory.category_id;
      }

      const response = await axios.post(
        'businessEntities/updateMenuCategory',
        payload,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        fetchData();
        setSnackbarMessage(isEditMode ? 'Category updated successfully.' : 'Category added successfully.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Error: Operation failed.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 3000);
    }
  };


  const handleDelete = async (category_id) => {
    try {
      const response = await axios.delete(`businessEntities/deleteCategory/${category_id}`);

      if (response.status === 200) {
        fetchData();
        setSnackbarMessage("Category deleted successfully");
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting category item:", error);
      setSnackbarMessage("Failed to delete category");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      setSnackbarMessage("Please upload a valid CSV file.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024; // 5 mb
    if (file.size > maxFileSize) {
      setSnackbarMessage("File size exceeds the maximum limit of 5MB.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurant_id', sessionStorage.getItem("configBizEntityId"));

    setIsUploading(true);

    try {
      const response = await axios.post(
        '/businessEntities/upload-category-csv',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: false,
        }
      );

      const successMessage = response.data.message || "Category CSV uploaded successfully!";
      fetchData();

      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

    } catch (error) {
      let errorMessage = 'Something went wrong with the menu CSV upload!';

      if (error.response) {
        errorMessage = error.response.data.error || `Error: ${error.response.statusText}`;
      } else if (error.request) {
        errorMessage = 'No response from the server. Please try again later.';
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      console.error('Error uploading CSV:', error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const filteredCategories = categories.filter(category =>
    category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a href="/assets/templates/category.csv" download="category.csv" style={{ textDecoration: 'none' }}>
            Download CSV Template ?
          </a>
        </div>
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search categories"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddCategory}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Category
          </Button>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
            style={{ backgroundColor: '#2196F3', color: 'white' }}
            startIcon={<CloudUploadIcon />}
          >
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </Button>
        </div>
        {searchTerm !== '' && filteredCategories.length === 0 && categories.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your category search!</span>
          </div>
        )}
        {categories.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No menu categories added yet</span>
          </div>
        )}
      </div>
      <Grid container spacing={3}>
        {filteredCategories.map(category => (
          <Grid item key={category.category_id} xs={12} sm={6} md={4} lg={3}>
            <CategoryCard
              category={category.category_name}
              description={category.description ?? 'Satisfying main dishes for a hearty meal.'}
              onEdit={() => handleEditCategory(category)}
              onDelete={() => handleDelete(category.category_id)}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="category-modal"
        aria-describedby="category-description"
      >
        <div className="modal-container">
          <h6 id="category-modal">{isEditMode ? 'Edit Category' : 'Add New Category'}</h6>
          <div>
            <label>Category Name</label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
            <label>Description</label>
            <textarea
              value={newCategoryDescription}
              onChange={(e) => setNewCategoryDescription(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <Button
              variant="contained"
              onClick={handleAddOrEditCategorySubmit}
            >
              {isEditMode ? 'Update Category' : 'Add Category'}
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CategoryList;
