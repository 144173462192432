import React, { useState } from 'react';
import { GroupOutlined, MenuBookRounded, CategoryOutlined, SummarizeOutlined, Accessible, TableRestaurant } from '@mui/icons-material';
import AddNewSpecialUser from '../../app/components/dashboard/pages/productconfig/special/users/add_new_user';
import SpecialUsersList from '../../app/components/dashboard/pages/productconfig/special/users/view_users_list';
import CategoryList from '../../app/components/dashboard/pages/productconfig/special/category/category-list';
import MenuItemList from '../../app/components/dashboard/pages/productconfig/special/menu/lists/menu-list';
import SectionList from '../../app/components/dashboard/pages/productconfig/special/section/lists/section_list';
import TablesList from '../../app/components/dashboard/pages/productconfig/special/tables/lists/table-list';
import AgentSidebar from '../components/AgentSidebar';
import Navbar from '../../app/components/dashboard/components/navbar/Navbar';

export const userInputs = [
  {
    id: 1,
    label: "Full Name",
    type: "text",
    placeholder: "User's Name",
  },
  {
    id: 2,
    label: "Email",
    type: "text",
    placeholder: "Email",
  },
  {
    id: 3,
    label: "Phone No",
    type: "text",
    placeholder: "Phone Number",
  },
  {
    id: 4,
    label: "Age",
    type: "text",
    placeholder: "age",
  },

];


const tabsData = [
  {
    icon: <GroupOutlined />, label: "User Updation", content: <div style={{ height: '100vh', overflow: 'auto' }}>
      <><AddNewSpecialUser inputs={userInputs} /><SpecialUsersList /></>
    </div>
  },
  { icon: <CategoryOutlined />, label: "Category Updation", content: <CategoryList/> },
  { icon: <MenuBookRounded />, label: "Menu Updation", content: < MenuItemList/> },
  { icon: <Accessible />, label: "Section Updation", content: <SectionList/> },
  { icon: <TableRestaurant />, label: "Tables Updation", content: <TablesList/> },
];

const AgentConfigLiteSpecial = () => {
  
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="list">
      <AgentSidebar />
      <div className="listContainer flex flex-col h-screen overflow-hidden">
        <Navbar />
        <div className="tabs-container  flex-grow overflow-y-auto ">
          <div className="tab-list">
            {tabsData.map((tab, index) => (
              <div
                key={index}
                className={`tab-item ${selectedTab === index ? 'selected' : ''}`}
                onClick={() => handleTabSelect(index)}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>

          <div className="tab-panels">
            {tabsData.map((tab, index) => (
              <div key={index} className={`tab-panel ${selectedTab === index ? 'selected' : ''}`}>
                {selectedTab === index && (
                  <div>
                    {/* <h5>{tab.label}</h5> */}
                    {tab.content}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentConfigLiteSpecial;
