import React, { useState } from 'react';
import { GroupOutlined, MenuBookRounded, AccessTimeRounded, KitchenRounded, SettingsBackupRestoreRounded, Inventory } from '@mui/icons-material';
import UnitsConversionDatatable from '../../app/components/dashboard/components/datatable/unitsConversionDataTable';
import UnitsDatatable from '../../app/components/dashboard/components/datatable/unitsDataTable';
import IngredientsDatatable from '../../app/components/dashboard/components/datatable/ingredientsDataTable';
import RecipesDatatable from '../../app/components/dashboard/components/datatable/recipesDataTable';
import RecipesAndMenuDatatable from '../../app/components/dashboard/components/datatable/recipesMenuDataTable';
import SpecialUsersList from '../../app/components/dashboard/pages/productconfig/special/users/view_users_list';
import AddNewSpecialUser from '../../app/components/dashboard/pages/productconfig/special/users/add_new_user';
import AgentSidebar from '../components/AgentSidebar';
import Navbar from '../../app/components/dashboard/components/navbar/Navbar';



export const userInputs = [
    {
      id: 1,
      label: "Full Name",
      type: "text",
      placeholder: "User's Name",
    },
    {
      id: 2,
      label: "Email",
      type: "text",
      placeholder: "Email",
    },
    {
      id: 3,
      label: "Phone No",
      type: "text",
      placeholder: "Phone Number",
    },
    {
      id: 4,
      label: "Age",
      type: "text",
      placeholder: "age",
    },
  
  ];
const tabsData = [
  {
    icon: <GroupOutlined />, label: "User Updation", content: <div style={{ height: '100vh', overflow: 'auto' }}>
      <><AddNewSpecialUser inputs={userInputs} /><SpecialUsersList /></>
    </div>
  },
  {
    icon: <SettingsBackupRestoreRounded />,
    label: "Unit Updation",
    content: <UnitsDatatable />, 
  },
  {
    icon: <AccessTimeRounded />,
    label: "Unit Conversions",
    content: <UnitsConversionDatatable />,
  },
  {
    icon: <KitchenRounded />,
    label: "Ingredients Updation",
    content: <IngredientsDatatable />, 
  },
  {
    icon: <MenuBookRounded />,
    label: "Recipe Updation",
    content: <RecipesDatatable/>, 
  },
  {
    icon: <MenuBookRounded />,
    label: "Menu Updation",
    content: <RecipesAndMenuDatatable/>,
  }
];

const AgentsAdvancedInventoryConfig = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="list">
      <AgentSidebar />
      <div className="listContainer flex flex-col h-screen overflow-hidden">
        <Navbar />
        <div className="tabs-container flex-grow overflow-y-auto">
          <div className="tab-list">
            {tabsData.map((tab, index) => (
              <div
                key={index}
                className={`tab-item ${selectedTab === index ? 'selected' : ''}`}
                onClick={() => handleTabSelect(index)}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>

          <div className="tab-panels">
            {tabsData.map((tab, index) => (
              <div key={index} className={`tab-panel ${selectedTab === index ? 'selected' : ''}`}>
                {selectedTab === index && (
                  <div>{tab.content}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default  AgentsAdvancedInventoryConfig;
