import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../lists/table-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';
import TableCard from '../cards/table _card';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function TablesList() {
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const [searchTerm, setSearchTerm] = useState('');
  const [tables, setTables] = useState([]);

  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  // Modal states
  const [openModal, setOpenModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [editMode, setEditMode] = useState(false);
  const [editTableId, setEditTableId] = useState(null);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const fetchData = async () => {
    try {
      const responseCategories = await axios.get(`businessEntities/getAllSections/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setSections(responseCategories.data.allSections);

      const responseTables = await axios.get(`businessEntities/getAllTables/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setTables(responseTables.data.allTables);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [business_entity_id]);

  const handleAddItem = () => {
    setEditMode(false);  
    setNewItemName('');
    setSelectedSection(null);
    setOpenModal(true);
  };

  const handleEditItem = (tableId, tableName, sectionId) => {
    setEditMode(true);  
    setEditTableId(tableId);
    setNewItemName(tableName);
    setSelectedSection(sectionId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddOrUpdateSubmit = async () => {
    if (!newItemName || !selectedSection) {
      setSnackbarMessage("Both table name and section are required.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }
  
    try {
      const payload = {
        tableName: newItemName,
        sectionId: selectedSection,
        businessEntityId: business_entity_id
      };
  

  
      if (editMode) {
        payload.tableId = editTableId;
      }
  
      const response = await axios.post('businessEntities/updateTable', payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
  
      if (response.status === 200) {
        setSnackbarMessage(editMode ? "Table updated successfully!" : "Table added successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      }
  
      setOpenModal(false);
      fetchData();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage(editMode ? "Error updating table." : "Error adding table.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  
  
  const handleDelete = async (table_id) => {
    try {
      const response = await axios.delete(`businessEntities/deleteTable/${table_id}`);
      
      if (response.status === 200) {
        fetchData();
        setSnackbarMessage("Table deleted successfully");
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting table item:", error);
      setSnackbarMessage("Failed to delete table");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
 

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      setSnackbarMessage("Please upload a valid CSV file.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxFileSize) {
      setSnackbarMessage("File size exceeds the maximum limit of 5MB.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurant_id', business_entity_id);

    setIsUploading(true);

    try {
      const response = await axios.post(
        '/businessEntities/upload-table-csv',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: false,
        }
      );

      const successMessage = response.data.message || "CSV uploaded successfully!";
      fetchData();

      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

    } catch (error) {
      console.error('Error uploading CSV:', error);
      setSnackbarMessage('Something went wrong with the CSV upload.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const filteredItems = tables.filter(item =>
    item.table_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <p style={{ textDecoration: 'none', color: 'red' }}>
            Please ensure that the section name is spelled correctly when using the template. This will ensure proper linking; otherwise, a new section will be created.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a href="/assets/templates/table.csv" download="table.csv" style={{ textDecoration: 'none' }}>
            Download CSV Template?
          </a>
        </div>
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search table"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddItem}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Table
          </Button>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
            style={{ backgroundColor: '#2196F3', color: 'white' }}
            startIcon={<CloudUploadIcon />}
          >
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </Button>
        </div>
        {searchTerm !== '' && filteredItems.length === 0 && tables.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your table search!</span>
          </div>
        )}
        {tables.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No tables added yet</span>
          </div>
        )}
      </div>
      <Grid container spacing={3}>
        {filteredItems.map(item => (
          <Grid item key={item.menu_id} xs={12} sm={6} md={4} lg={3}>
            <TableCard
              tableName={item.table_name}
              section={item.section}
              onEdit={() => handleEditItem(item.table_id, item.table_name, item.section_id)}
              onDelete={() => handleDelete(item.table_id)}
            />
          </Grid>
        ))}
      </Grid>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-item-modal"
        aria-describedby="add-item-description"
      >
        <div className="modal-container">
          <h6 id="add-item-modal">Add New Table</h6>
          <div>
            <label htmlFor="item-name">Item Name:</label>
            <input
              id="item-name"
              type="text"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </div>
          <div className="formInput">
            <label>Section:</label>
            <select className="dropdown" value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
              <option value={null}>Select a section</option>
              {sections.map(e => (
                <option key={e.section_id} value={e.section_id}>
                  {e.section_name}
                </option>
              ))}
            </select>
            <Button style={{ float: 'right' }} variant="contained" onClick={handleAddOrUpdateSubmit}>Save Table</Button>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default TablesList;
