import "./navbar.scss";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";


const Navbar = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const isStandardUser = userData.super_role_id === 0;
  const configurePath = sessionStorage.getItem("configurePath") ?? '';

  return (
    <div className="navbar">
      <div className="wrapper">{
        isStandardUser ?
          <div>
            {userData.business_name && <div className="route-text">{`${configurePath ? 'CONFIGURING' : 'BUSINESS NAME'}: ${userData.business_name.toUpperCase()}${configurePath ? "/" : ""}${configurePath.toUpperCase()}`}</div>}
          </div> : <>
          <div className="route-text">
            AGENT DASHBOARD
          </div>
          </>}
    
        <div className="items">

          <div className="item">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;