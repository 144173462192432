import BBQImage from '../../../elements/bbq-image';
import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../globals/constants";
import { register } from 'swiper/element/bundle';
register();

function SectionSlider() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isStandardUser = isAuthenticated && userData.super_role_id === 0;

    return (
        <>
            <div className="sx-bnr-2-wrap-outer home-2-slider">
                {/* swiper slides */}
                <swiper-container
                    loop="true"
                    space-between="30"
                    effect="fade"
                    navigation-next-el=".swiper-button-next"
                    navigation-perv-el=".swiper-button-prev"
                    pagination-el=".swiper-pagination"
                    pagination-clickable="true"
                    parallax="true"
                    autoplay-delay="7000"
                    autoplay-disable-on-interaction="true"
                    class="swiper-wrapper"
                >
                    <swiper-slide class="sx-bnr-2-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider3.jpg")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-2-content">
                                <span className="sx-bnr-2-small-title">Welcome to BuyByeQ  PLATFORM</span>
                                <h2 className="sx-bnr-2-large-title">Transform Your Restaurant with Smart Digital Solutions!</h2>
                                <div className="sx-bnr-2-info">Empowering restaurants with innovative technology to simplify operations, enhance customer experiences, and drive growth. Discover how BuybyeQ can streamline your processes and elevate your business.</div>
                                <div className="sx-bnr-readmore">
                                    {!isAuthenticated ? <NavLink to="/registration" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Register Your Business
                                    </NavLink> : <NavLink to={isStandardUser?"/config":"/agents/dashboard"} className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        View My Dashboard
                                    </NavLink>}
                                </div>
                                {/* <div className="sx-bnr-video">
                                    <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                                        <i className="flaticon-play" />
                                    </a>
                                </div> */}
                            </div>

                        </div>
                        <div className="sx-bnr-images">
                            <BBQImage src="images/main-slider/slider2/sm-pic1.png" alt="" />
                        </div>
                    </swiper-slide>
                    <swiper-slide class="sx-bnr-2-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider2.jpg")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-2-content">
                                <span className="sx-bnr-2-small-title">Elevating your business</span>
                                <h2 className="sx-bnr-2-large-title">Attention restaurant owners and managers.</h2>
                                <div className="sx-bnr-2-info">  Are you a restaurant owner or manager looking to streamline your operations, enhance customer experiences, and boost your business's success? Look no further! Join BuyByeQ and unlock a world of possibilities.</div>
                                <div className="sx-bnr-readmore">
                                    {!isAuthenticated ? <NavLink to="/registration" className="site-button sx-btn-primary icon">
                                        <i className="fa fa-long-arrow-right" />
                                        Register Your Business
                                    </NavLink> : <NavLink to="/config" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        View My Dashboard
                                    </NavLink>}
                                </div>
                                {/* <div className="sx-bnr-video">
                                    <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                                        <i className="flaticon-play" />
                                    </a>
                                </div> */}
                            </div>
                        </div>

                        <div className="sx-bnr-images">
                            <BBQImage src="images/main-slider/slider2/sm-pic2.png" alt="" />
                        </div>
                    </swiper-slide>
                </swiper-container>
                {/* !swiper slides */}
                {/* Add Arrows */}
                <div className="swiper-button-next" />
                <div className="swiper-button-prev" />
            </div>
        </>
    )
}

export default SectionSlider;