import { NavLink } from "react-router-dom";
import BBQImage from "../../elements/bbq-image";
import { useState } from "react";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { useNavigate } from 'react-router-dom'

function HeaderMain() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isStandardUser = isAuthenticated && userData.super_role_id === 0;

    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(() => {
        loadScript("js/mobilenav.js")
    })

    return (
        <>
            <header className={"header-style2 site-header mobile-sider-drawer-menu full-navbar " + (isActive ? "active" : "")}>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix ">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <BBQImage src="images/BBQ_LOGO.png" alt="#" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                           
                            {/* EXTRA Nav */}
                            {/* EXTRA NAV */}
                            <div className="extra-nav d-flex align-content-center d-none d-lg-block">
                             

                                    <ul className="sidenav-main gap-4">
                                    <li  ><NavLink to="/contact-us" className="sidenav">Contact Us</NavLink></li>
                                    {!isAuthenticated ? (
                                        <li><NavLink to="/login" className="sidenav">Login</NavLink></li>
                                    ) : (
                                        <li>
                                        <NavLink to={isStandardUser?"/config":"/agents/dashboard"} className="sidenav">Dashboard</NavLink>
                                    </li>
                                    )}
                                    </ul>
                                   
                             

                            </div>
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    <li className="active ">
                                        <NavLink to="/" className="text-decoration-none">Home</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/about-us">Company</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/about-us">About BuyByeQ</NavLink></li>
                                            <li><NavLink to="/faq">Faq Page</NavLink></li>
                                            <li><NavLink to="/testimonials">Testimonials</NavLink></li>
                                        </ul>
                                    </li> */}
                                    <li><NavLink to="/faq" className="text-decoration-none">FAQ</NavLink></li>
                                    {/* <li>
                                        <NavLink to="/accounting-and-renewal">Accounting & Renewal</NavLink>
                                    </li> */}
                                    {/* {isAuthenticated ? <li>
                                        <NavLink to="/config">Configuration</NavLink>
                                    </li> : <></>} */}
                                    
                                    <li><NavLink to="/all-products" className="text-decoration-none">Products</NavLink></li>





                                    <li  ><NavLink to="/contact-us"  className="text-decoration-none d-lg-none d-md-none">Contact Us</NavLink></li>
                                    {!isAuthenticated ? (
                                        <li><NavLink to="/login" className="text-decoration-none d-lg-none d-md-none">Login</NavLink></li>
                                    ) : (
                                        <li>
                                        <NavLink to="/config"  className="text-decoration-none d-lg-none d-md-none">Dashboard</NavLink>
                                    </li>
                                    )}
                                  
                                    
                                    </ul>
                              
                            </div>
                             
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default HeaderMain;