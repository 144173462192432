import { BorderTop } from "@mui/icons-material";
import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";

function TestimonialsPage() {
    return (
        <>
            {/* <Banner _data={bannerData.testimonials} /> */}

            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                {/* TESTIMONIAL START */}
                <div className="container">
                    <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head center">
                            <div className="sx-head-s-title">Our Testimonials</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title">What Clients Say?</h2>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="masonry-outer mfp-gallery news-grid clearfix  ">
                            {/* COLUMNS 1 */}
                            <div className="masonry-item  m-b30 " >
                                <div className="testimonial-3  hover-animation-1 sx-bg-light   col-lg-8 mx-auto " style={{


                                    right: 0,

                                    borderRight: '7px solid orange'
                                }}>


                                    <div className="imgtestimonial">
                                        <img
                                            src="/img.jpg"
                                            alt="testimonial"


                                            style={{ width: '900px', height: '100%', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div>
                                        <div className="testimonial-detail text-left px-5 py-2">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">Restaurant Name: Brew Times</span>
                                                <h4 className="testimonial-name" >Owner’s Name: Ms. Arpita Mishra</h4>
                                                <h4 className="testimonial-name" >Product: Special Billing</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text text-left px-5 ">
                                            <p>Since switching to BuyByeQ, managing our restaurant has become very easy. It saves our time and reduce errors, making our entire operation smoother. Highly recommend this for any restaurant looking to streamline and improve efficiency.</p>
                                        </div>
                                        <div className="testimonial-footer px-5">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                            {[...Array(5)].map((_, index) => (
                                                <li key={index} style={{ marginRight: '4px' }}>
                                                    <span>
                                                        <i className="fa fa-star" style={{ color: '#FFD700', fontSize: '1.2em' }} />
                                                    </span>
                                                </li>
                                            ))}
                                            </ul>
                                            <span className="sx-review-count">(5)</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* COLUMNS 2 */}
                            
                        </div>
                    </div>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default TestimonialsPage;