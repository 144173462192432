import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../cards/menu_card.scss';

function MenuItemCard({
    item,
    onEdit,
    onDelete
}) {
    return (
        <Card className="menu-item-card" variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    {item.menu_name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" component="p">
                    Price: Rs{item.price}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" component="p">
                    CGST: {item.cgst}%, SGST: {item.sgst}%
                </Typography>
                {item.code && <Typography variant="subtitle2" color="textSecondary" component="p">
                    CODE: {item.code}
                </Typography>}
                <div className="button-container">
                    <IconButton onClick={onEdit} aria-label="edit" className="edit-button">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={onDelete} aria-label="delete" className="delete-button">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </CardContent>
        </Card>
    );
}

export default MenuItemCard;
