import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from 'react-router-dom';
import AgentSidebar from "../components/AgentSidebar";
import Navbar from "../../app/components/dashboard/components/navbar/Navbar";


const AgentsRestaurantSetup = () => {
  const [businessEntities, setBusinessEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleTables, setVisibleTables] = useState({});
  const [searchQuery, setSearchQuery] = useState(""); 
  const navigate = useNavigate();
  const location = useLocation();
  
  const fetchData = async () => {
    try {
      setLoading(true);
      
      const entitiesResponse = await axios.get(`/businessEntities/getAllRegisteredBusinessEntities`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      const entitiesWithRestaurants = await Promise.all(
        entitiesResponse.data.map(async (entity) => {
          const restaurantsResponse = await axios.get(`/businessEntities/getAllBusinessEntities/${entity.group_id}`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          });
          return { ...entity, restaurants: restaurantsResponse.data };
        })
      );
     
      setBusinessEntities(entitiesWithRestaurants);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to load data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (location.state?.refresh) {
      fetchData();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state]);

  const columns = [
    { field: 'business_entity_id', headerName: 'ID', width: 70 },
    { field: 'business_name', headerName: 'Restaurant Name', width: 200 },
    { field: 'name', headerName: 'Contact Person', width: 150 },
    { field: 'contact_no', headerName: 'Mobile', width: 120 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'address', headerName: 'Address', width: 300 },
    {
      field: 'isConfigured',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => (
        <>
          <button 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handleConfig(params.row.business_entity_id)}
          >
          
          </button>
        </>
      )
    },
  ];

  const handleConfig = (businessEntityId) => {
    navigate(`/agents/config/restaurants/${businessEntityId}`);
  };

  const handleAddRestaurant = (groupId) => {
    const selectedEntity = businessEntities.find(entity => entity.group_id === groupId);
    
    if (selectedEntity) {
      localStorage.setItem('selectedBusinessEntity', JSON.stringify(selectedEntity));
      navigate(`/agents/config/restaurants/register`, { state: { groupId } });
    } else {
      console.error("Selected business entity not found");
    }
  };

  const toggleTableVisibility = (groupId) => {
    setVisibleTables(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEntities = businessEntities.filter(entity => 
    entity.business_entity_id.toString().includes(searchQuery) || 
    entity.business_name.toLowerCase().includes(searchQuery.toLowerCase()) 
  );

  if (loading) {
    return <div className="new">
    <AgentSidebar />
    <div className="newContainer flex flex-col h-screen overflow-hidden">
      <Navbar/>
      <div className="container flex-grow overflow-y-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Restaurant Setup</h1>
        <div className="text-center py-10">Loading...</div>
      </div>
    </div>
  </div>
    
    ;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <div className="new">
      <AgentSidebar />
      <div className="newContainer flex flex-col h-screen overflow-hidden">
        <Navbar/>
        <div className="container flex-grow overflow-y-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Restaurant Setup</h1>

          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by ID or Business Name..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded-lg px-4 py-2 w-full"
            />
          </div>

          {filteredEntities.map((entity) => (
            <div key={entity.group_id} className="mb-8 border rounded-lg overflow-hidden">
              <div className="bg-gray-100 p-4">
                <div>
                  <h2 className="text-xl font-semibold">{entity.business_name}</h2>
                  <p><strong>ID:</strong> {entity.group_id}</p>
                  <p><strong>Owner:</strong> {entity.name}</p>
                  <p><strong>Contact:</strong> {entity.contact_no}</p>
                  <p><strong>Email:</strong> {entity.email}</p>
                </div>
                <div className="mt-4">
                  <button 
                    onClick={() => handleAddRestaurant(entity.group_id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    Add Restaurant
                  </button>
                  <button 
                    onClick={() => toggleTableVisibility(entity.group_id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    {visibleTables[entity.group_id] ? 'Hide Restaurants' : 'View Restaurants'}
                  </button>
                </div>
              </div>
              {visibleTables[entity.group_id] && (
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={entity.restaurants || []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => row.business_entity_id}
                  />
                </div>
              )}
            </div>
          ))}
          {filteredEntities.length === 0 && (
            <p className="text-center py-4">No matching business entities found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentsRestaurantSetup;
