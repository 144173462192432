import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import AgentSidebar from '../components/AgentSidebar';
import Navbar from '../../app/components/dashboard/components/navbar/Navbar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const newRestaurantUrl = "/businessEntities/addBusinessEntity";

function AgentsAddNewRestaurants({ title = "Add New Restaurant" }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    businessName: '',
    Manager: '',
    ManagerEmail: '',
    Email: '',
    contactNo: '',
    Address: '',
    Country: '',
    GstNumber: '',
  });
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false); 

  const inputs = [
    { id: 'businessName', label: "Restaurant Name", type: "text", placeholder: "Enter Restaurant Name" },
    { id: 'Manager', label: "Manager", type: "text", placeholder: "Enter manager name" },
    { id: 'ManagerEmail', label: "Manager Email", type: "email", placeholder: "Enter Manager Email" },
    { id: 'Email', label: "Restaurant Email", type: "email", placeholder: "Enter Restaurant Email" },
    { id: 'contactNo', label: "Restaurant Phone", type: "number", placeholder: "Enter Restaurant Phone" },
    { id: 'Address', label: "Address", type: "text", placeholder: "Enter Address" },
    { id: 'Country', label: "Country", type: "text", placeholder: "Enter Country" },
    { id: 'GstNumber', label: "GST Number", type: "text", placeholder: "Enter GST Number" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const userData = JSON.parse(localStorage.getItem("selectedBusinessEntity"));

    try {
      const result = await axios.post(newRestaurantUrl, {
        businessEntityId: userData.business_entity_id,
        businessName: formData.businessName,
        Email: formData.Email,
        contactNo: formData.contactNo,
        Address: formData.Address,
        Group_ID: userData.group_id,
        Business_type: userData.business_type,
        Manager: formData.Manager,
        ManagerEmail: formData.ManagerEmail,
        GstNumber: formData.GstNumber,
      }, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      if (result.status === 200) {
        setSuccess('Restaurant registered successfully!');
        setOpenSnackbar(true); 
        setTimeout(() => {
          navigate(-1); 
        }, 2000);
        
      }
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while registering the restaurant.');
    }
  };

  return (
    <div className="new">
      <AgentSidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom" style={{ padding: "40px" }}>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Logo:
                  <div className="left">
                    <img
                      src={file ? URL.createObjectURL(file) : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
                      alt="Restaurant logo"
                    />
                  </div>
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    type={input.type}
                    name={input.id}
                    placeholder={input.placeholder}
                    value={formData[input.id]}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
              <button type="submit">Save Restaurant</button>
            </form>
            {error && <div className="error-message">{error}</div>}
          </div>
        </div>
      </div>

   
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default AgentsAddNewRestaurants;
