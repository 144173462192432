export const bannerData = {
    about: {
        background: "images/banner/about.jpg",
        title: "About Us",
        crumb: "About Us",
        watermark: "POS Solutions"
    },
    testimonials: {
        background: "images/banner/testimonials.png",
        title: "Testimonials",
        crumb: "Testimonials",
        watermark: "POS Solution"
    },
    products: {
        background: "images/banner/darkbgg.jpg",
        title: "Products",
        crumb: "Our Products",
        watermark: "POS Solutions"
    },
    config: {
        background: "images/banner/darkbgg.jpg",
        title: "Configuration",
        crumb: "Configuration",
        watermark: "POS Solutions"
    },
    faq: {
        background: "images/banner/darkbgg.jpg",
        title: "FAQs Page",
        crumb: "Faqs Page",
        watermark: "FAQs"
    },
    contact: {
        background: "images/banner/darkbgg.jpg",
        title: "Contact Us",
        crumb: "Contact Us",
        watermark: "POS Solutions"
    }
}